import "./lib/jquery.min.js";
import _ from "./lib/lodash.min.js";
import Clusterize from "./lib/clusterize.js";
window._ = _;
window.Clusterize = Clusterize;
import "./bg.polyfill.js";
import "./batchgeo-session/batchgeo-session.js";
import "./lib/colorbox.js";
import "./batchgeo/batchgeo.js";
import "./batchgeo/batchgeo-app-reducer.js";
import "./batchgeo-store/batchgeo-store.js";
import "./batchgeo-track/batchgeo-track.js";
import "./lib/linkify.min.js";
import "./batchgeo-strings/batchgeo-strings.js";
import "./batchgeo-devices/batchgeo-devices.js";
import "./batchgeo-api/batchgeo-api.js";
import "./batchgeo-access-view/batchgeo-access-view.js";
import "./batchgeo-persistence/batchgeo-persistence.js";
import "./bg.login.js";
