(function (window) {
  if (window.BatchGeoStrings) return;
  /**
   * BatchGeoStrings is a i18n component that allows clients to fetch strings
   * based on the language preference of the user. The component has a
   * globally set "strings" property that is set with #setStrings(). That
   * method will allow you to set a preferred language and fallback. The
   * fallback is used when no translations exist for that component or a
   * specific string.
   *
   * It features the ability to add new lines with \r that get converted to
   * <br> tags as well as string replacements for dynamic strings. See the
   * documentation on the BatchGeoStringSelector.get to learn more about these
   * features!
   *
   * @example
   * window.BatchGeoStrings.setStrings({
   * 		preferred: {
   * 			"MyComponentName": {
   * 				A_STRING_KEY: "This string you will be able to access with get('A_STRING_KEY')"
   * 			},
   * 		},
   * 		fallback: {
   * 			"MyComponentName": {
   * 				A_STRING_KEY: "This string you will be able to access with get('A_STRING_KEY')"
   * 			},
   * 			"MyOtherComponent": {
   * 				SOME_STRING: "If preferred.MyOtherComponent is called it will use this since it doesnt exist in preferred."
   * 			}
   * 		}
   * })
   *
   * var strings = window.BatchGeoStrings.getStringsForComponent('MyComponentName');
   * var otherStrings = window.BatchGeoStrings.getStringsForComponent('MyOtherComponent')
   *
   * console.log(strings.get('A_STRING_KEY')) // returns the first A_STRING_KEY above
   * console.log(strings.get('SOME_STRING')) // returns the SOME_STRING under fallback
   * console.log(strings.get('DOES_NOT_EXIST')) // returns an empty string
   *
   * @see {@link BatchGeoStringSelector.get} For information on formatting
   * strings with new lines and template tags.
   *
   * @constructor
   */
  var BatchGeoStrings = {
    // This is just here to show where the strings will be put at
    strings: undefined,
  };

  /**
   * This constructor is meant to be an internal one to allow for selecting
   * strings under a namespace (componentName).
   * @param {string} componentName The component's name you want to search for
   * strings under.
   * @private
   * @constructor
   */
  var BatchGeoStringSelector = function (componentName) {
    this.componentName = componentName;
  };
  /**
   * Will search for a string based on the stringKey param you pass here
   * inside the component you selected with getStringsForComponent(). It
   * supports new lines by passing in \r which will get converted to <br> and
   * string replacements with mustache style templates like {{foo}}.
   *
   * See the examples below on using all those!
   *
   * @example
   *
   * // Assuming your have a string setup like:
   * {
   *   "MyComponent": {
   *     STRING_1: "Hello, World",
   *     STRING_2: "Hello, {{name}}"
   *     STRING_3: "Hello,\rDown There"
   *   }
   * }
   *
   * var strings = BatchGeoStrings.getStringsForComponent('MyComponent');
   *
   * // Basic example
   * strings.get('STRING_1')
   * // => Hello, World
   *
   * // Templating example
   * strings.get('STRING_2', {name: 'Oscar'})
   * // => Hello, Oscar
   *
   * // New line example
   * strings.get('STRING_3')
   * // => Hello,<br>Down There
   *
   * @param {string} stringKey The key name of the string to fetch.
   * @param {object} [template] A template hash that for any template tags
   * found in the string will get replaced with the value in the template.
   *
   * @returns {string} Will return either matching string or an empty string
   * if none match.
   */
  BatchGeoStringSelector.prototype.get = function (stringKey, template) {
    template = template || {};
    var strings = BatchGeoStrings.strings || {};

    // If the component and key exists return that otherwise return an empty
    // string as a fallback.
    var string =
      (strings[this.componentName] && strings[this.componentName][stringKey]) ||
      "";

    return (
      string
        // Instead of requiring HTML in the strings allow for an escaped return
        // and then convert into HTML to make translators less error prone.
        .replace(/\r/g, "<br>")
        // Convert template tags to a real value
        .replace(/\{\{([^}]*)}}/gi, function (originalString, key) {
          return template[key] !== undefined ? template[key] : originalString;
        })
    );
  };

  /**
   * Narrows down the strings to a component. This is for convenience, shorter
   * strings, as well as preventing collisions in strings. It will return a
   * new object with the get() method to fetch the strings you need. If an
   * entire component name is missing it will try to return
   *
   * @example
   * var strings = window.BatchGeoStrings.getStringsForComponent('MyComponentName')
   * console.log(strings.get('A_STRING_KEY')) // will output your string!
   *
   * @param {string} componentName The key of the component object to search
   * for.
   *
   * @returns {BatchGeoStringSelector} Returns a BatchGeoStringSelector
   * instance so you can call get() against the selected component.
   */
  BatchGeoStrings.getStringsForComponent = function (componentName) {
    return new BatchGeoStrings.stringSelector(componentName);
  };

  /**
   * Sets the strings to be looked up. It expects a `preferred` and an
   * optional `fallback` option which has an object of strings inside of that.
   * Remember this is a singleton. Setting strings means all calls to get
   * strings later will reflect the strings you passed in here.
   *
   * Important note: you should not call this async. If you call this async
   * components fetching these strings will never get the strings. Meaning
   * if you called:
   *
   * 1. MyComponentName
   * 2. BatchGeoStrings.setStrings
   * 3. MyOtherComponent
   *
   * MyComponentName will have any strings and will get "" back from the get()
   * call since no strings were set.
   *
   * @example
   * window.BatchGeoStrings.setStrings({
   * 		preferred: {
   * 			"MyComponentName": {
   * 				A_STRING_KEY: "This string you will be able to access with get('A_STRING_KEY')"
   * 			},
   * 		},
   * 		fallback: {
   * 			"MyComponentName": {
   * 				A_STRING_KEY: "This string you will be able to access with get('A_STRING_KEY')"
   * 			},
   * 			"MyOtherComponent": {
   * 				SOME_STRING: "If preferred.MyOtherComponent is called it will use this since it doesnt exist in preferred."
   * 			}
   * 		}
   * })
   *
   * @param {Object} strings
   */
  BatchGeoStrings.setStrings = function (strings) {
    if (!strings || !strings.preferred) {
      throw new Error(
        "No preferred strings were provided. This may result in empty strings when no fallback string is found.",
      );
    }

    BatchGeoStrings.strings = _.merge({}, strings.fallback, strings.preferred);
  };

  BatchGeoStrings.stringSelector = BatchGeoStringSelector;
  window.BatchGeoStrings = BatchGeoStrings;
})(window);

/**
 * You might be wondering why this is being initialized here and not some other
 * component such as site.js, batchgeo-map.js, etc. Well, currently, there's
 * only ever one instantiation of BatchGeoStrings (in fact, it's singleton). If
 * we don't do it here we have to put this across tons of files with identical
 * code. Before this commit it was in 5 files and it needed to go in at least 2
 * more. This code is here to DRY code up so there's only ever one file calling
 * setStrings. Move this if there's ever a need to actually call setStrings for
 * different components or pages.
 */
(function (window) {
  window.__tr18n_strings__ &&
    window.BatchGeoStrings.setStrings(window.__tr18n_strings__);
})(window);
