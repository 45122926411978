/**
 * BatchGeoAppReducer
 *
 * Sets up a global function to act as our global top level state reducer within BatchGeoStore.
 * Initialized as the default reducer in batchgeo-store.js upon store initialization
 */
window.BatchGeoAppReducer = function (state, action) {
  state = state || {
    baseUrl: "",
    isDev: false,
    isLite: false,
    isEdit: false,
    isMap: false,
    // 0 = map + data
    // 1 = map only
    mapRenderMethod: 0,
    version: 0,
    mapId: 0,
    mapAlias: null,
    mapSecret: null,
    pro: false,
    over: "",
    proMap: false,
    advanced: false,
    heatmap: false,
    cluster: false,
    shouldRenderTable: false,
    isExporting: false,
    lastToggledFeature: null,
    units: "km",
    currentUser: {
      email: null,
      isMapOwner: false,
    },
    // load indicators
    mapLoaded: false,
    outliers: [],
    tableLoaded: false,
    librariesLoaded: false,
    markerCount: 0,
    mapMode: "",
    language: "en",
    nodeServiceBaseUrl: "",
    staticNodeServiceBaseUrl: "",
    buildRowsWorkerUrl: "",
    printGridWorkerUrl: "",
    printListWorkerUrl: "",
    tableSortWorkerUrl: "",
  };

  switch (action.type) {
    case "HYDRATE_INITIAL_STATE":
      return $.extend(true, {}, state, {
        baseUrl: action.baseUrl,
        currentUser: action.currentUser,
        mapRenderMethod: action.mapRenderMethod,
        mapId: action.mapId,
        mapAlias: action.mapAlias,
        mapSecret: action.mapSecret,
        advanced: action.advanced,
        // minimal data manipulation to convert to booleans
        isDev: !!action.isDev,
        isLite: !!action.isLite,
        over: action.over,
        pro: !!action.pro,
        proMap: !!action.proMap,
        version: parseInt(action.version),
        heatmap: !!parseInt(action.heatmap),
        cluster: !!parseInt(action.cluster),
        shouldRenderTable: action.shouldRenderTable,
        isExporting: action.isExporting,
        markerCount: action.markerCount,
        mapMode: action.mapMode,
        language: action.language,
        nodeServiceBaseUrl: action.nodeServiceBaseUrl,
        staticNodeServiceBaseUrl: action.staticNodeServiceBaseUrl,
        buildRowsWorkerUrl: action.buildRowsWorkerUrl,
        printGridWorkerUrl: action.printGridWorkerUrl,
        printListWorkerUrl: action.printListWorkerUrl,
        tableSortWorkerUrl: action.tableSortWorkerUrl,
      });

    case "EDIT_PAGE":
      return Object.assign({}, state, {
        isEdit: action.isEdit,
      });

    case "MAP_PAGE":
      return Object.assign({}, state, {
        isMap: action.isMap,
      });

    case "SET_MAP_UNITS":
      return Object.assign({}, state, {
        units: action.units,
      });

    /**
     * Toggles global feature variables and persists that data with
     * BatchGeoPersistence
     */
    case "FEATURE_TOGGLE": {
      // action.feature passed when dispatching this must be a state prop!
      const newState = {};
      newState[action.feature] = !state[action.feature];
      newState.lastToggledFeature = action.feature;
      return Object.assign({}, state, newState);
    }

    case "FEATURE_ENABLE": {
      const newState = {};
      newState[action.feature] = true;
      newState.lastToggledFeature = action.feature;
      return Object.assign({}, state, newState);
    }

    case "FEATURE_DISABLE": {
      const newState = {};
      newState[action.feature] = false;
      newState.lastToggledFeature = action.feature;
      return Object.assign({}, state, newState);
    }

    case "MAP_LOADED":
      return Object.assign({}, state, {
        mapLoaded: true,
      });

    case "TABLE_LOADED":
      return Object.assign({}, state, {
        tableLoaded: true,
      });

    case "LIBRARIES_LOADED":
      return Object.assign({}, state, {
        librariesLoaded: true,
      });

    case "OUTLIERS_DETECTED":
      return Object.assign({}, state, {
        outliers: [...action.outliers],
      });

    case "CLEAR_OUTLIERS":
      return Object.assign({}, state, {
        outliers: [],
      });

    default:
      return state;
  }
};
