/**
 * BatchGeoSession is a set of user session utilities that allow you to login, logout
 * and create a forgot password request.
 */
(function (window) {
  var BASE_URL = "/api/";

  /**
   * Sends a login request and if successful it will reload the page without the
   * cache so it works on any subpage page. If it fails it will show an alert.
   *
   * @example
   * BatchGeoSession.login($emailInput.val(), $passwordInput.val())
   *
   * @param {string} email The email to login with
   * @param {string} password The password to try with that email
   * @param {function} [callback] A jQuery $.ajax#complete callback that is
   * fired once the request completes
   */
  function login(email, password, callback) {
    $.ajax({
      type: "POST",
      url: BASE_URL + "login/",
      data: {
        email: email,
        password: password,
      },
      complete: callback || $.noop,
    });
  }

  /**
   * Logs the user out and then sends them back to the home page
   *
   * @example
   * BatchGeoSession.logout()
   */
  function logout() {
    $.ajax({
      url: BASE_URL + "logout/",
      complete: function () {
        window.location = "/";
      },
      cache: false,
    });
    // clear saved local storage values on logout
    try {
      localStorage.clear();
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Given an email, it will send a forgot password link to that email (if that user
   * exists). It will first try a forgot password for pro users and if that is
   * unsuccessful it will hit the `emailMaps` endpoint which checks for non-pro
   * users who have saved maps.
   *
   * When the reset is complete (successful or not) it will call the callback param
   * and also handle alerting the user on the status for you. For example, if the
   * email given doesn't exist it'll automatically pop open an alert telling the
   * user that.
   *
   * @example
   * alert("We're processing your forgot password request");
   * BatchGeoSession.forgotPassword($emailInput.val(), function () {
   *   alert("Forgot password request complete")
   * })
   *
   * @param {string} email The email to attempt the reset on
   * @param {function} [callback] Function to call after reset is don't processing
   */
  function forgotPassword(email, callback) {
    callback = callback || function () {};

    var xhrData = {
      email: escape(email),
    };

    if (email) {
      // First we hit the forgot endpoint which looks for forgotten passwords
      // for paying users with accounts
      $.ajax({
        url: BASE_URL + "forgot/",
        data: xhrData,
        complete: function (data) {
          data = parseInt(data.responseText);
          // If it returns 0 that means there were no users matching that so
          // we hit the emailMaps endpoint to look for maps created with
          // that email but don't have a login.
          if (data === 0) {
            $.ajax({
              url: BASE_URL + "emailMaps/",
              data: xhrData,
              success: function (edata) {
                BatchGeo.customAlert(edata);
                callback();
              },
            });
          } else if (data > 0) {
            BatchGeo.customAlert("Ok, you should receive the email shortly");
            callback();
          }
        },
      });
    } else {
      BatchGeo.customAlert(
        'Ok, just enter your email address into the field on the sign-in box and click "Forgot Password" again.',
      );
      callback();
    }
  }

  window.BatchGeoSession = {
    login: login,
    logout: logout,
    forgotPassword: forgotPassword,
  };
})(window);
