/**
 * BatchGeoPersistence provides APIs to persist data client side in a safe way.
 * Most client side storage methods will give you a hard, process killing, error
 * when the storage is full or in private browsing. BatchGeoPersistence will let
 * you try to save without worrying about that. If the browser is throwing
 * errors trying to save you will wont notice. Your code will just assume
 * nothing has persisted yet and your cache is empty.
 *
 * It provides full CRUD: `set` (create and update), `fetch` (read), `destroy`.
 * It also stores the timestamps and component version. This way you can handle
 * destroying the cache if you need to make a major change to the component or
 * destroy the data if you made a product change at some point and want to clear
 * the data for any instance's from a certain point back.
 *
 * Each instance has it's own store based on your given key. If not key is given
 * it will use a default storage container.
 *
 * @example
 * // Assuming a first time user...
 *
 * // Create the instance
 * var myCache = new BatchGeoPersistence({ key: 'MyCoolComponent' });
 * => { }
 *
 * // Save initial data
 * myCache.set({foo: true, bar: 'baz'});
 * => { foo: true, bar: 'baz' }
 *
 * // Update `foo` and add a new property (bar stays untouched)
 * myCache.set({foo: false, hello: 'world'});
 * => { foo: false, bar: 'baz', hello: 'world'  }
 *
 * // Fetch the data later
 * myCache.fetch();
 * => { foo: false, bar: 'baz', hello: 'world'  }
 *
 * // Fetch one item
 * myCache.fetch('hello');
 * => 'world'
 *
 * // Remove specific properties
 * myCache.destroy(['hello', 'foo']);
 * => { bar: 'baz' }
 *
 * // Destroy it all
 * myCache.destroy()
 * => { }
 *
 * NOTE: Currently it only supports localStorage, but in the future you could
 * add drivers for other persistence methods such sessionStorage or IndexedDB.
 */
(function () {
  /**
   * The constructor which takes options and will return the current state of
   * the persistent data.
   *
   * Currently there is only one available option which is "key". Pass a key of
   * what you want to store the data under. Try to use a unique name as your
   * data will overwrite anything that is using the same key. i.e. "cache" is a
   * bad name but i.e. "MyCoolComponentCache" is a good name.
   *
   * @param {object} options See the above description of the available options
   * @return {object} Returns a new BatchGeoPersistence instance
   * @example
   * var myCache = new BatchGeoPersistence({ key: 'MyCoolComponent' });
   * => { }
   *
   * @constructor
   */
  var BatchGeoPersistence = function (options) {
    this.options = Object.assign(
      {},
      {
        key: "BatchGeoPersistenceDefaultStorage",
      },
      options,
    );

    // Setup the internal timestamp storage object
    this._proxyStorage(function (storage) {
      if (!this._toObject(storage.BatchGeoPersistence).timestamps) {
        // One line try/catch since there's nothing to catch here.
        try {
          storage.BatchGeoPersistence = '{"timestamps":{}, "version":1}';
        } catch (err) {
          console.log(`Error setting up storage ${err}`);
        }
      }
    });
  };

  /**
   * A function to wrap all internals around that would have otherwise called
   * localStorage directly. This function automatically wraps everything
   * inside in a try/catch. The first param is the safe storage object you can
   * use which is either localStorage or an in-memory Object.
   *
   * For this reason don't wrap large blocks of code that don't need to be
   * wrapped in a try catch as you will have errors gobbled up that don't need
   * to be. For example:
   *
   * this._proxyStorage(function (storage) {
   *   new BatchGeoTable(...)
   *   storage.foo = 'bar';
   * })
   *
   * Will cause any errors returned from the table to go missing...poof.
   * Instead you should write:
   *
   * new BatchGeoTable(...)
   *
   * this._proxyStorage(function (storage) {
   *   storage.foo = 'bar';
   * })
   *
   * @param {function} proxyStorageFunction A function to call with a
   * try/catch around it. If localStorage is accessible it will pass back
   * localStorage as the first param. If not it will pass an in-memory object.
   *
   * @returns {*} Will return whatever the proxyStorageFunction returns
   */
  BatchGeoPersistence.prototype._proxyStorage = function (
    proxyStorageFunction,
  ) {
    // This is used for in-memory storage when localStorage can't be used
    if (!this._inMemoryStorage) this._inMemoryStorage = {};

    try {
      return proxyStorageFunction.call(this, localStorage);
    } catch (e) {
      return proxyStorageFunction.call(this, this._inMemoryStorage);
    }
  };

  /**
   * Will either convert your string to an object or your falsy value to an
   * empty `{}`. It's the "safe" way to always get back an object.
   *
   * @param {string|undefined|null} json
   * @return {object} Either your string parsed to an object or an empty object
   * @private
   */
  BatchGeoPersistence.prototype._toObject = function (json) {
    return json ? JSON.parse(json) : {};
  };

  /**
   * Converts an object to a JSON string
   *
   * @param {object} obj The object to convert to a string
   * @return {string} a JSON string representation of your object
   * @private
   */
  BatchGeoPersistence.prototype._toString = function (obj) {
    return JSON.stringify(obj);
  };

  /**
   * A helper that returns the instance's data from the storage as an object
   *
   * @return {object} the instance's data as an object
   * @private
   */
  BatchGeoPersistence.prototype._getThisPersistenceData = function () {
    return this._proxyStorage(function (storage) {
      return this._toObject(storage[this.options.key]);
    });
  };

  /**
   * Creates and stores a timestamp for the instance's key whenever it is called
   * in the format of UNIX time (.getTime())
   *
   * @return {undefined}
   * @private
   */
  BatchGeoPersistence.prototype._createTimestampEntry = function () {
    // Try to save it in localStorage...
    this._proxyStorage(function (storage) {
      var bgPersist = this._toObject(storage.BatchGeoPersistence);

      // Store the current modified date
      bgPersist.timestamps[this.options.key] = new Date().getTime();

      storage.BatchGeoPersistence = this._toString(bgPersist);
    });
  };

  /**
   * Merges the given data to the instance's persistent storage. If a property
   * is new it is added. If a property exists it will be updated. It supports
   * two formats:
   *
   * 1. set({key: value})
   * 2. set(key, value)
   *
   * The first format gives you much more flexibility and unlimited key/value
   * sets to merge. The second option is useful if you have a dynamic key name
   * such as "set(myComponentInstanceId(), "my value")
   *
   * @param {object|string|number} dataOrProperty The data to merge or the property name
   * @param {*} [propertyValue] The data to merge. Only used if 1st param is obj
   * @return {object} The current state of the instance's persistent storage
   * @example
   * // Save initial data
   * myCache.set({foo: true, bar: 'baz'});
   * => { foo: true, bar: 'baz' }
   *
   * // Update `foo` and add a new property (bar stays untouched)
   * myCache.set({foo: false, hello: 'world'});
   * => { foo: false, bar: 'baz', hello: 'world'  }
   *
   * // Using alt syntax for setting data for a dynamic key value
   * myCache.set(getComponentId(), "my value");
   */
  BatchGeoPersistence.prototype.set = function (dataOrProperty, propertyValue) {
    var persistenceData = this._getThisPersistenceData();

    // If dataOrProperty is a property (a string) then convert it to an object
    // with a {key: value} format
    if (typeof dataOrProperty !== "object") {
      var data = {};
      data[dataOrProperty] = propertyValue;
      dataOrProperty = data;
    }

    var newPersistenceData = Object.assign({}, persistenceData, dataOrProperty);

    this._proxyStorage(function (storage) {
      storage[this.options.key] = this._toString(newPersistenceData);
      this._createTimestampEntry();
    });

    // Refetch localStorage because it may not have changed if an error happened
    return this._getThisPersistenceData();
  };

  /**
   * Returns the current state of the instance's persistent storage or, if given
   * a property, return the value of that property.
   *
   * @param {string} [property] A shorthand to get a property within the data
   * @return {object} The current state of the instance's persistent storage
   * @example
   * myCache.fetch();
   * => { foo: false, bar: 'baz', hello: 'world'  }
   *
   * * myCache.fetch('hello');
   * => 'world'
   */
  BatchGeoPersistence.prototype.fetch = function (property) {
    if (!property) {
      return this._getThisPersistenceData();
    } else {
      return this._getThisPersistenceData()[property];
    }
  };

  /**
   * Returns metadata about the data persisted. Currently it only supports
   * a modified data.
   *
   * @return {object} Meta data about the data persisted
   * @example
   * myCache.fetchMetadata();
   * => { modified: 1472630755656 }
   */
  BatchGeoPersistence.prototype.fetchMetadata = function () {
    return this._proxyStorage(function (storage) {
      return {
        modified: this._toObject(storage.BatchGeoPersistence).timestamps[
          this.options.key
        ],
      };
    });
  };

  /**
   * Destroys all data if no props are passed otherwise will only remove the
   * properties in the given array.
   *
   * @param {*} [properties] A property or list of properties to remove
   * @return {object} The current state of the instance's persistent storage
   * @example
   * // Remove a specific property
   * myCache.destroy('hello');
   * => { foo: true, bar: 'baz' }
   *
   * // Remove specific properties
   * myCache.destroy(['hello', 'foo']);
   * => { bar: 'baz' }
   *
   * // Destroy it all
   * myCache.destroy()
   * => { }
   */
  BatchGeoPersistence.prototype.destroy = function (properties) {
    if (!properties) {
      this._proxyStorage(function (storage) {
        delete storage[this.options.key];
      });
    } else {
      if (!(properties instanceof Array)) {
        properties = [properties];
      }

      var persistenceData = this._getThisPersistenceData();

      properties.forEach(function (prop) {
        delete persistenceData[prop];
      });

      this._proxyStorage(function (storage) {
        storage[this.options.key] = this._toString(persistenceData);
      });
    }

    this._createTimestampEntry();
    return this._getThisPersistenceData();
  };

  window.BatchGeoPersistence = BatchGeoPersistence;
})();
